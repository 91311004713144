import React from "react";
import Layout from "../components/common/layout/layout";
import { Navigation } from "../components/common/navigation/navigation";
import { QuizletComparePage } from "../components/pages/QuizletComparePage";
import { Footer } from "../components/sections/footer";
import { quizletAlternative } from "../utils/seoText";

import SEO from "../components/common/layout/seo";
const CompareQuizletPage = () => {
  return (
    <Layout>
      <SEO {...quizletAlternative} />
      <Navigation />
      <QuizletComparePage />
      <Footer />
    </Layout>
  );
};

export default CompareQuizletPage;
