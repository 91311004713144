import React from "react";
import { ComparePage } from "./ComparePage";

import tw from "twin.macro";
import { CardEditorFeature } from "../sections/features/CardEditorFeature";
import { ChromeFeature } from "../sections/features/ChromeFeature";
import { ScienceBackedFeature } from "../sections/features/ScienceBackedFeature";
import { QuizletAlternativeTestimonials } from "../sections/testimonials/TestimonialSections";
import { HowItWorks } from "../sections/howitworks";

export const QuizletComparePage: React.FC = () => {
  return (
    <>
      <ComparePage
        title={
          <>
            <h1 className="text-2xl font-extrabold tracking-tight text-center text-gray-900 xs:text-4xl sm:text-5xl md:text-6xl">
              A powerful Quizlet alternative that{" "}
              <span className="inline-block text-purple-600 xl:block">
                makes studying significantly faster.
              </span>
            </h1>
            <p className="w-full mx-auto text-xs text-center text-gray-500 xs:text-base sm:text-lg lg:text-xl md:max-w-3xl">
              Quizlet puts a price on efficient learning and basic features like
              card customization. <br className="hidden md:block" />
              Zorbi is a free and community-driven flashcard app that ex-Quizlet
              users love.
            </p>
          </>
        }
      >
        <QuizletAlternativeTestimonials />
        <HowItWorks />
        <CardEditorFeature />
        <div className="-mt-20">
          <ChromeFeature sideOverride={"right"} showCaption={false} />
        </div>
        <div className="bg-white">
          <ScienceBackedFeature />
        </div>
      </ComparePage>
    </>
  );
};
